import React from 'react'
import { Container, Grid } from '@mui/material'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import ArticleCard from '../../components/article-card'

const BlogIdPage = ({ data }: any) => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const { blogJson } = data
    const blog = {
        ...blogJson,
    }
    const articles = blogJson.allArticleJson

    const title = `${blog.site} | ${siteTitle}`
    const description = blog.site
    return (
        <Layout title={title} description={description}>
            <Container sx={{ pt: { xs: 3, sm: 6 } }}>
                <Grid container columnSpacing={3} rowSpacing={6}>
                    {articles.map((article: any) => {
                        return (
                            <Grid key={article.id} item xs={12} sm={6} md={4}>
                                <ArticleCard article={article} blog={blog} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query ($blog_id: String) {
        blogJson(blog_id: { eq: $blog_id }) {
            blog_id
            blog_url
            category
            feed_url
            id
            site
            tags
            image {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        width: 96
                        height: 54
                        transformOptions: { cropFocus: CENTER }
                        quality: 90
                    )
                }
            }
            allArticleJson(
                sort: { fields: blog___articles___created, order: DESC }
            ) {
                id
                title
                description
                url
                image
                content
                categories
                created
                published
                localImage {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            width: 320
                            height: 180
                            transformOptions: { cropFocus: CENTER }
                            quality: 90
                        )
                    }
                }
            }
        }
    }
`

export default BlogIdPage
